import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout/Layout'
import Navigation from '../components/Navigation/Navigation'
import Seo from '../components/Seo/Seo'
import { getPostDate } from '../scripts/scripts'
import '../scss/pages/knowledge-zone.scss'
import LongArrow from '../assets/images/icons/arrow-long.svg'
import config from '../../config.json'
import ReferrerLink from '../components/ReferrerLink/ReferrerLink'

const KnowledgeZone = () => {
    const articlesCount = 6;

    const data = useStaticQuery(query);
    const seo = data.wpPage.seo;
    const topPosts = data.allWpPost.edges.slice(0, 2);
    const bottomPosts = data.allWpPost.edges.slice(2, 2 + articlesCount);
    const storagePosts = data.allWpPost.edges.slice(2 + articlesCount);

    if(typeof window !== 'undefined'){
        sessionStorage.setItem('articles', JSON.stringify(storagePosts));
    }
    if(typeof document !== `undefined`) {
        document.addEventListener('scroll', function(e) {
            if(Boolean(document.getElementsByClassName('infinite-scroll').length) && typeof window !== 'undefined') {
                let scrollPosition = window.scrollY;
                var lastArticle = document.querySelector('.article:last-child');
    
                if(scrollPosition > lastArticle.offsetTop) {
                    let articlesList = JSON.parse(sessionStorage.getItem('articles'));
                    let articlesToShow = articlesList.slice(0, articlesCount);
                    sessionStorage.setItem('articles', JSON.stringify(articlesList.slice(articlesCount)));
    
                    let html = '';
    
                    articlesToShow.forEach(article => {
                        const image = getImage(article.node.featuredImage.node.localFile);
                        let imageUrl = image.images.fallback.src.replace(/(\s)/, "\\ ");
                    
                        html += `
                                    <div class="col-md-4 article">
                                        <div class="post-box">
                                            <a class="post-link" href="/strefa-wiedzy/`+ article.node.slug + `/" title=`+ article.node.title +`>
                                                <div class="post-img" style="background-image: url('`+imageUrl+`')"></div>
                                                <div class="post-info">
                                                    <span><i class="date-icon"></i>`+ getPostDate(article.node.dateGmt) +`</span>
                                                </div>
                                                <h2 class="post-title">`+ article.node.title +`</h2>
                                                <p class="post-description">`+ article.node.strefaWiedzy.zajawka.slice(0, 140) + `...` +`</p>
                                            </a>
                                        </div>
                                    </div>
                                `;
                    });
    
                    document.querySelector('.posts-container').innerHTML += html;
                }
            }
        });
    }

    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/" />
            <div id="page-header" className="header blog-header">
                <Navigation/>
                <div className="container pt-30">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            <p className="header-tile">Strefa wiedzy</p>
                            <h1 className="text-white title pt-45">Blog - artykuły pełne wiedzy</h1>
                            <p className="paragraph text-white mb-0 container-paragraph">Redaktorzy naszego serwisu stale pracują nad rzeczowym omówieniem kolejnych kluczowych zagadnień. Pomożemy Ci ustalić, jak najlepiej wykorzystać ubezpieczenie grupowe pracowników w swojej firmie, zarówno małej, jak i większej. Warto więc zaglądać do nas regularnie i sprawdzać kolejne materiały. Zapraszamy!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="new-post">
                <div className="custom-container new-post-container">
                    <div className="row">
                        {topPosts.map((post, index) => {
                            const image = getImage(post.node.featuredImage.node.localFile)
                            return  <div className="col-md-6">
                                        <div className="post-box">
                                            <a className="post-link" href={`/strefa-wiedzy/${post.node.slug}/`} title={post.node.title}>
                                                {post.node.featuredImage && (
                                                    <div className="post-img" style={{ backgroundImage: `url(${image.images.fallback.src})` }} />
                                                )}
                                                <div className="post-info">
                                                    <span><i className="date-icon"></i>{getPostDate(post.node.dateGmt)}</span>
                                                </div>
                                                <h2 className="post-title">{post.node.title}</h2>
                                                <p className="post-description">{post.node.strefaWiedzy.zajawka.slice(0, 140) + '...'}</p>
                                            </a>
                                        </div>
                                    </div>
                            })}
                    </div>
                </div>
            </div>
            <div className="bottom-posts infinite-scroll">
                <div className="round-section">
                    <div className="bg-container"></div>
                    <div className="content-box">
                        <div className="header-box">
                            <h2 className="title">Jak kupić<br />ubezpieczenie<br />pracowników?</h2>
                            <div className="cta-btns-group pt-30">
                                <ReferrerLink activeClass="cta-radius referrer-link" text="Dopasuj ubezpieczenie" />

                                <a href="/ranking-ubezpieczen/" className="cta">Sprawdź ranking <img className="arrow" src={LongArrow} alt="Sprawdź ranking" title="Sprawdź ranking" /></a>
                            </div>
                        </div>
                        <div className="list-box">
                            <ul className="list">
                                <li>Wypełnij formularz <span className="text-yellow">potrzeb<br />Twojej firmy</span></li>
                                <li>Wysłuchaj oferty i odbierz maila z<br/><span className="text-yellow">potrzeb Twojej firmy</span></li>
                                <li>Wybierz najlepszą i oszczędź<br/><span className="text-yellow">nawet 39%</span> kosztów polisy</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="custom-container">
                    <div className="row posts-container">
                        {bottomPosts.map((post, index) => { 
                            const image = getImage(post.node.featuredImage.node.localFile)
                            return  <div className="col-md-4 article">
                                    <div className="post-box">
                                        <a className="post-link" href={`/strefa-wiedzy/${post.node.slug}/`} title={post.node.title}>
                                            {post.node.featuredImage && (
                                                <div className="post-img" style={{ backgroundImage: `url(${image.images.fallback.src})` }} />
                                            )}
                                            <div className="post-info">
                                                <span><i className="date-icon"></i>{getPostDate(post.node.dateGmt)}</span>
                                            </div>
                                            <h2 className="post-title">{post.node.title}</h2>
                                            <p className="post-description">{post.node.strefaWiedzy.zajawka.slice(0, 140) + '...'}</p>
                                        </a>
                                    </div>
                                </div>
                            })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default KnowledgeZone

export const query = graphql`
  {
    wpPage(slug: {eq: "strefa-wiedzy"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Strefa wiedzy"}}}}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          strefaWiedzy {
            zajawka
          }
          slug
          dateGmt
        }
      }
    }
  }
`
